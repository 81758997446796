import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import classnames from "classnames";
import { UserContext } from "../contexts/UserContext";
import Tooltip from "@material-ui/core/Tooltip";
import { DashboardDrawer } from "./DashboardDrawer";
import { margin } from "../style/theme";
import { TabAccessContext } from "../contexts/TabAccessContext";
import { Loading } from "../components/Loading";
import MMenuItem from "@material-ui/core/MenuItem";
import { PermissionsContext } from "../contexts/PermissionsContext";
import Dashboard from "@material-ui/icons/Dashboard";
import Terms from "@material-ui/icons/ViewAgendaRounded";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "../contexts/LanguageContext";
import { fixedOemLocations, dynamicOemLocations } from "../charts/DashboardTabs";
import PeopleIcon from "@material-ui/icons/People";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import DirectionsCarIcon2 from "@material-ui/icons/DirectionsCarTwoTone";
import BuildIcon from "@material-ui/icons/Build";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import { Avatar, ClickAwayListener, createMuiTheme, Grid, Grow, ListItemIcon, MenuList, MuiThemeProvider, Paper, Popper, Typography } from "@material-ui/core";
import { MenuComp } from "./MenuComp";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TranslateIcon from "@material-ui/icons/Translate";
import { Settings } from "@material-ui/icons";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Burger from "@material-ui/icons/Menu";
import msx from "./msx.png";
import withWidth, { isWidthUp, WithWidth, WithWidthOptions, isWidthDown } from "@material-ui/core/withWidth";
import { Redirect } from "react-router";

export const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
        textAlign: "center",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "13px",
        textAlign: "left",
        fontWeight: "normal",
      },
      subheading: {
        fontSize: "13px",
        textAlign: "left",
        fontWeight: "normal",
      },
    },
    MuiDrawer: {
      paper: {
        position: "unset",
        overflow: "hidden",
      },
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
  },
});

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#eee",
      width: "100%",
    },
    mobileMenu: {
      display: "block",
      // [theme.breakpoints.up("md")]: {
      //   display: "none",
      // },
    },
    appBar: {
      backgroundColor: "#FFFFFF",
      color: "#191919",
      border: "none",
      width: "100%",
      overflow: "hidden",
      margin: "0 auto",
    },
    logo: {
      padding: `0 ${theme.spacing.unit}px`,
      lineHeight: 0,
      paddingBottom: "2px",

      "& img": {
        width: "60px",
        height: "50px",
      },
      "@media (max-width:380px)": {
        width: "150px",
      },
      "@media (min-width:380px) and (max-width:420px)": {
        width: "200px",
      },
    },
    main: {},
    menu: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      margin: "auto",
      maxWidth: margin.width,
      background: "#FFFFFF",
      position: "sticky",
      top: 0,
      zIndex: 9998,
    },
    nav: {
      display: "grid",
      gridTemplateRows: "repeat(12, 1fr)",
      backgroundColor: "#ffffff",
      height: "100%",
      position: "fixed",
      zIndex: 1,
      top: 0,
      left: 0,
      overflow: "hidden",
    },
    closeProfile: {
      justifyContent: "center",
    },
    openProfile: {
      placeItems: "left",
    },
    closeNav: {
      justifyContent: "center",
      width: "70px",
      transition: "width .5s",
      "@media (max-width: 920px) and (orientation: landscape)": {
        overflowY: "hidden",
      },
      "@media (max-width: 959px)": {
        display: "none",
      },
    },
    openNav: {
      width: "227px",
      transition: "width .5s",
      overflowY: "hidden",
      zIndex: 9999,

      "@media (max-width:620px) and (orientation: portrait)": {
        width: "100%",
        overflow: "hidden",
      },
      "@media (max-width: 920px) and (orientation: landscape)": {
        width: "100%",
        overflow: "hidden",
      },

      "@media (max-width: 1024px) and (orientation: landscape)": {
        overflow: "hidden",
      },
    },
    mainMargin: {
      marginLeft: "0",
    },
    closeMain: {
      transition: "margin-left .5s",
      [theme.breakpoints.up("md")]: {
        marginLeft: "70px",
      },
    },
    openMain: {
      marginLeft: "227px",
      transition: "margin-left .5s",
    },
    mItem: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    userPopup: {
      zIndex: 9999999,
    },
    profile: {
      cursor: "pointer",
      width: "100%",
      display: "grid",
      alignItems: "center",
      height: "50px",
      gridTemplateColumns: "1fr 0fr",
      paddingLeft: "21px",
      paddingRight: "21px",

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
      },
    },
    profileMenu: {
      display: "flex",
      alignItems: "flex-end",
      gridRow: "12/13",
    },
    flex: { flex: "1 1 auto" },
    menuItem: {
      // height: "50px",
    },
    menuItems: {
      width: "150px",
    },
    menuItemLabel: {
      width: "80px",
    },
    avatar: {
      borderRadius: "50%",
      overflow: "hidden",
      width: 24,
      height: 24,
      backgroundColor: "#333",
      alignItems: "center",
      justifyContent: "center",
    },
    image: {
      margin: 10,
    },
    adminItem: {
      backgroundColor: "#191919",
    },
    profileName: {
      paddingRight: "1rem",
      fontWeight: "normal",
      fontSize: "13px",
    },
    profileImage: {
      height: "24px",
      width: "24px",
      // border: "solid 1px black",
    },
    transition: {
      transform: "scale(0,0)",
    },
    filterburger: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    burger: {
      color: "#191919",
      margin: theme.spacing.unit * 2,
      cursor: "pointer",
    },
    hideMenuItem: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        display: "flex",
      },
    },
    menuIcon: {
      color: "#191919",
    },
    subMenuItem: {
      "&:hover": {
        color: "#000",
      },
    },
    languageArrow: {
      color: "#191919",
      margin: "auto",
    },
    passwordField: {
      width: "20em",
    },
    pinMenu: {
      cursor: "pointer",
      margin: "auto",
      width: "90%",
      height: "2em",
      border: "none",
      gridRow: "11/12",
    },
    navMenu: {
      gridRow: "1/11",
    },
  });

export type DrawerProps = { toggle?: boolean } & WithStyles<typeof styles> & WithWidthOptions & WithWidth & React.HTMLAttributes<HTMLDivElement>;

type LocationType = {
  url: string;
  to: string;
  label: string;
  count: number;
  className: string;
  children: LocationChildrenType[];
  placeholder?: boolean;
};

type LocationChildrenType = {
  to: string;
  id: string;
  label: string;
  count: number;
  placeholder?: boolean;
  children: any[];
};

export const adminLocations = {
  users: {
    url: "/admin/users",
    to: "#",
    label: "admin.users.header",
    src: <PeopleIcon />,
    className: "",
    count: 0,
    children: [
      { to: "/admin/users/manage", label: "admin.manage.users.subheader", id: "editUsers", count: 0, children: [] },
      { to: "/admin/users/roles", label: "admin.roles.subheader", id: "editRoles", count: 0, children: [] },
    ],
  },
  dealers: {
    url: "/admin/dealers",
    to: "#",
    label: "admin.dealers.header",
    src: <DirectionsCarIcon />,
    count: 0,
    className: "",
    children: [
      { to: "/admin/dealers/manage", label: "admin.manage.dealers.subheader", id: "editDealers", count: 0, children: [] },
      { to: "/admin/dealers/mappings", label: "admin.mappings.subheader", id: "dealerMapping", count: 0, children: [] },
      { to: "/admin/dealers/dealerset", label: "admin.dealer.set.subheader", id: "editDealerSet", count: 0, children: [] },
      { to: "/admin/dealers/datareload", label: "admin.data.reload.subheader", id: "dataReload", count: 0, children: [] },
      { to: "/admin/dealers/submissionreload", label: "Submission Reload", id: "dataReload", count: 0, children: [] },
    ],
  },
  dealerGroups: {
    url: "/admin/dealergroups",
    to: "#",
    label: "admin.dealer.groups.header",
    src: <DirectionsCarIcon2 />,
    className: "",
    count: 0,
    children: [{ to: "/admin/dealergroups/manage", label: "admin.manage.dealergroups.subheader", id: "editDealerGroups", count: 0, children: [] }],
  },
  oemsetup: {
    url: "/admin/oemsetup",
    to: "#",
    label: "admin.oem.setup.header",
    src: <BuildIcon />,
    className: "",
    count: 0,
    children: [
      { to: "/admin/oemsetup/manage", label: "admin.manage.oem.subheader", id: "editDistributors", count: 0, children: [] },
      { to: "/admin/oemsetup/inputtemplates", label: "admin.input.templates.subheader", id: "inputTemplates", count: 0, children: [] },
    ],
  },
  uploadschedule: {
    url: "/admin/uploadschedule",
    to: "#",
    label: "admin.upload.schedule.header",
    src: <AddToPhotosIcon />,
    className: "",
    count: 0,
    children: [{ to: "/admin/uploadschedule/manage", label: "admin.manage.uploadschedule.subheader", id: "uploadSchedule", count: 0, children: [] }],
  },
  dataadmin: {
    url: "/admin/dataadmin",
    to: "#",
    label: "admin.data.admin.header",
    src: <ShowChartIcon />,
    className: "",
    count: 0,
    children: [
      {
        to: "/admin/dataadmin/uploadmarketsharedata",
        label: "admin.upload.market.share.data.subheader",
        id: "cube_market_shares",
        count: 0,
        children: [],
      },
      { to: "/admin/dataadmin/marketconsolidation", label: "admin.market.consolidation.header", id: "cube_marketshare", count: 0, children: [] },
      {
        to: "/admin/dataadmin/automated-consolidation",
        label: "admin.automated.consolidation.subheader",
        id: "cube_marketshare$marketcons",
        count: 0,
        children: [],
      },
      { to: "/admin/dataadmin/benchmarks", label: "admin.benchmarks.header", id: "cube_benches", count: 0, children: [] },
      { to: "/admin/dataadmin/forecasts", label: "admin.forecasts.subheader", id: "cube_forecasts", count: 0, children: [] },
      { to: "/admin/dataadmin/cubereload", label: "Cube Reload", id: "cube_reload", count: 0, children: [] },
    ],
  },
  systemadmin: {
    url: "/admin/systemadmin",
    to: "#",
    label: "admin.system.admin.header",
    src: <LabelImportantIcon />,
    className: "",
    count: 0,
    children: [
      { to: "/admin/systemadmin/systemadmin", label: "admin.system.admin.subheader", id: "cube_admin$settings$index", count: 0, children: [] },
      {
        to: "#",
        label: "admin.ratio.engine.header",
        id: "RatioEngine",
        count: 0,
        children: [
          { to: "/admin/systemadmin/ratioengine/coaindicators", label: "admin.coa.indicators.subheader", id: "accounts", count: 0, children: [] },
          { to: "/admin/systemadmin/ratioengine/ratios", label: "admin.ratios.subheader", id: "maintainRatios", count: 0, children: [] },
          {
            to: "/admin/systemadmin/ratioengine/qualitygates",
            label: "admin.quality.gates.subheader",
            id: "maintainQualityGates",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/ratioengine/mastercoa", label: "admin.master.coa.subheader", id: "cube_accounts", count: 0, children: [] },
          { to: "/admin/systemadmin/ratioengine/measure", label: "admin.measure.subheader", id: "cube_measures", count: 0, children: [] },
          {
            to: "/admin/systemadmin/ratioengine/basemeasure",
            label: "admin.base.measure.subheader",
            id: "cube_base_measures",
            count: 0,
            children: [],
          },
        ],
      },
      {
        to: "#",
        label: "admin.report.writer.subheader",
        count: 0,
        children: [
          { to: "/admin/systemadmin/reportwriter/reports", label: "admin.report.subheader", id: "cube_reports", count: 0, children: [] },
          {
            to: "/admin/systemadmin/reportwriter/dashboards",
            label: "admin.dashboards.subheader",
            id: "cube_dashboards",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/reportwriter/datasets", label: "admin.datasets.subheader", id: "cube_datasets", count: 0, children: [] },
          {
            to: "/admin/systemadmin/reportwriter/senarioplanner",
            label: "admin.senarioplanner.subheader",
            id: "cube_what_if$setup",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/reportwriter/report", label: "admin.reportmenu.subheader", id: "editReport", count: 0, children: [] },
        ],
      },
      {
        to: "/admin/reportseditor/reportseditor",
        label: "Report Descriptions",
        id: "reportDescription",
        count: 0,
        children: [],
      },
      {
        to: "/admin/systemadmin/batchserverthresholds",
        label: "admin.batch.server.thresholds.subheader",
        id: "cube_batch_thresholds$new",
        count: 0,
        children: [],
      },
      { to: "/admin/systemadmin/audit", label: "admin.audit.subheader", id: "cube_audit", count: 0, children: [] },
      {
        to: "#",
        label: "admin.options.subheader",
        id: "Options",
        count: 0,
        children: [
          // { to: "/admin/systemadmin/options/properties", label: "admin.properties.subheader", id: "editProperties", count: 0, children: [] },
          // {
          //   to: "/admin/systemadmin/options/olapproperties",
          //   label: "admin.cube.properties.subheader",
          //   id: "cube_admin$configurations$new",
          //   count: 0,
          //   children: [],
          // },
          { to: "/admin/systemadmin/options/logentries", label: "admin.log.entries.subheader", id: "log", count: 0, children: [] },
          { to: "/admin/systemadmin/options/sqlstatement", label: "admin.sql.statement.subheader", id: "sqlStatement", count: 0, children: [] },
          {
            to: "/admin/systemadmin/options/currency",
            label: "admin.currency.subheader",
            id: "cube_utils_currency_matrix",
            count: 0,
            children: [],
          },
          { to: "/admin/systemadmin/options/masterdata", label: "admin.master.data.subheader", id: "masterData", count: 0, children: [] },
        ],
      },
    ],
  },
};

export const locations = {
  dashboard: {
    url: "/dashboard",
    to: "/dashboard",
    src: <Dashboard />,
    label: "Dashboard",
    count: 0,
    className: "",
    children: [
      { to: "/dashboard", label: "Home", id: "Dashboard / Home", count: 0, children: [] },
      { to: "/dashboard/exec", label: "Executive", id: "Dashboard / Executive Summary", count: 0, children: [] },
      { to: "/dashboard/balance-sheet", label: "Balance Sheet", id: "Dashboard / Balance Sheet", count: 0, children: [] },
      { to: "/dashboard/new-fi", label: "NV F&I", id: "Dashboard / New F&I", count: 0, children: [] },
      { to: "/dashboard/new", label: "NV", id: "Dashboard / New", count: 0, children: [] },
      { to: "/dashboard/new-online", label: "NV Online", id: "Dashboard / New Online", count: 0, children: [] },
      { to: "/dashboard/used-fi", label: "UV F&I", id: "Dashboard / Used F&I", count: 0, children: [] },
      { to: "/dashboard/used", label: "UV", id: "Dashboard / Used", count: 0, children: [] },
      { to: "/dashboard/fi", label: "F&I", id: "Dashboard / F&I", count: 0, children: [] },
      { to: "/dashboard/parts", label: "Parts", id: "Dashboard / Parts", count: 0, children: [] },
      { to: "/dashboard/service", label: "Service", id: "Dashboard / Service", count: 0, children: [] },
      { to: "/dashboard/bodyshop", label: "Body Shop", id: "Dashboard / Body Shop", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  groupDashboard: {
    url: "/group-dashboard",
    to: "#",
    src: <PeopleIcon />,
    label: "Group Dashboard",
    count: 0,
    className: "",
    children: [
      { to: "/group-dashboard/exec", label: "Executive", id: "Group Dashboard / Executive Summary", count: 0, children: [] },
      { to: "/group-dashboard/balance-sheet", label: "Balance Sheet", id: "Group Dashboard / Balance Sheet", count: 0, children: [] },
      { to: "/group-dashboard/new-fi", label: "NV F&I", id: "Group Dashboard / New F&I", count: 0, children: [] },
      { to: "/group-dashboard/new", label: "NV", id: "Group Dashboard / New", count: 0, children: [] },
      { to: "/group-dashboard/new-online", label: "NV Online", id: "Group Dashboard / New Online", count: 0, children: [] },
      { to: "/group-dashboard/used-fi", label: "UV F&I", id: "Group Dashboard / Used F&I", count: 0, children: [] },
      { to: "/group-dashboard/used", label: "UV", id: "Group Dashboard / Used", count: 0, children: [] },
      { to: "/group-dashboard/fi", label: "F&I", id: "Group Dashboard / F&I", count: 0, children: [] },
      { to: "/group-dashboard/parts", label: "Parts", id: "Group Dashboard / Parts", count: 0, children: [] },
      { to: "/group-dashboard/service", label: "Service", id: "Group Dashboard / Service", count: 0, children: [] },
      { to: "/group-dashboard/bodyshop", label: "Body Shop", id: "Group Dashboard / Body Shop", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  OEMDashboard: {
    url: "/oem",
    to: "#",
    src: <DirectionsCarIcon />,
    label: "OEM Dashboard",
    count: 0,
    className: "",
    children: [
      {
        to: "/oem/fixed/home",
        label: "Fixed",
        id: "OEM Dashboard / Fixed",
        count: 0,
        children: [
          {
            to: "/oem/fixed/home",
            label: "Home",
            id: "OEM Dashboard / Fixed / Home",
            count: 0,
            children: [],
            placeholder: true,
          },
          {
            to: "/oem/fixed/fordacademy",
            label: "Ford Academy",
            id: "OEM Dashboard / Fixed / Ford Academy",
            count: 0,
            children: [],
            placeholder: true,
          },
          {
            to: "/oem/fixed/integrated-report",
            label: "Intergrated Report",
            id: "OEM Dashboard / Fixed / Intergrated Report",
            count: 0,
            children: [],
            placeholder: true,
          },
          {
            to: "/oem/fixed/risk",
            label: "Risk",
            id: "OEM Dashboard / Fixed / Risk",
            count: 0,
            children: [],
            placeholder: true,
          },
        ],
        placeholder: true,
      },
      {
        to: "/oem/dynamic/home",
        label: "Dynamic",
        id: "OEM Dashboard / Dynamic",
        count: 0,
        children: [
          {
            to: "/oem/dynamic/home",
            label: "Home",
            id: "OEM Dashboard / Dynamic / Home",
            count: 0,
            children: [],
            placeholder: true,
          },
          {
            to: "/oem/dynamic/fordacademy",
            label: "Ford Academy",
            id: "OEM Dashboard / Dynamic / Ford Academy",
            count: 0,
            children: [],
            placeholder: true,
          },
          {
            to: "/oem/dynamic/integrated-report",
            label: "Intergrated Report",
            id: "OEM Dashboard / Dynamic / Intergrated Report",
            count: 0,
            children: [],
            placeholder: true,
          },
          {
            to: "/oem/dynamic/risk",
            label: "Risk",
            id: "OEM Dashboard / Dynamic / Risk",
            count: 0,
            children: [],
            placeholder: true,
          },
        ],
        placeholder: true,
      },
      {
        to: "/oem/fordscorecard",
        label: "Ford Scorecard",
        id: "OEM Dashboard / Ford Scorecard",
        count: 0,
        children: [
          {
            to: "/oem/fordscorecard/home",
            label: "Home",
            id: "OEM Dashboard / Ford Scorecard / Home",
            children: [],
            count: 0,
            placeholder: true,
          },
          {
            to: "/oem/fordscorecard/group",
            label: "Group",
            id: "OEM Dashboard / Ford Scorecard / Group",
            children: [],
            count: 0,
            placeholder: true,
          },
        ],
        placeholder: true,
      },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  customDashboard: {
    url: "/custom",
    to: "#",
    src: <BuildIcon />,
    label: "Custom Dashboard",
    count: 0,
    className: "joyride-start",
    children: [
      { to: "/custom/dealer", label: "Dealer", id: "Dashboard / Custom", count: 0, children: [] },
      { to: "/custom/group", label: "Group", id: "Group Dashboard / Custom", count: 0, children: [] },
      { to: "/custom/distribution", label: "Distribution", id: "Distribution Dashboard / Custom", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  reports: {
    url: "/reports",
    to: "#",
    src: <EqualizerIcon />,
    label: "Reports",
    count: 0,
    className: "",
    children: [
      {
        to: "/reports/Standard Reports/All Reports",
        label: "Standard Reports",
        id: "Reports / Standard Reports",
        count: 0,
        children: [],
      },
      { to: "/reports/OEM Reports/All Reports", label: "OEM Reports", id: "Reports / OEM Reports", count: 0, children: [] },
      {
        to: "/reports/Dealer Insight",
        label: "Dealer Insight",
        id: "Reports / Dealer Insight",
        count: 0,
        children: [
          {
            to: "/reports/Dealer Insight/Performance Insight",
            label: "Performance Insight",
            id: "Reports / Dealer Insight / Performance Insight",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Dealer Insight/Project ROS",
            label: "Project ROS",
            id: "Reports / Dealer Insight / Project ROS",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Dealer Insight/Opportunity Report",
            label: "Opportunity Report",
            id: "Reports / Dealer Insight / Opportunity Report",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Dealer Insight/MRA Analysis",
            label: "MRA Analysis",
            id: "Reports / Dealer Insight / MRA Analysis",
            count: 0,
            children: [],
          },
        ],
      },
      {
        to: "/reports/Network Insight",
        label: "Network Insight",
        id: "Reports / Network Insight",
        count: 0,
        children: [
          {
            to: "/reports/Network Insight/Performance Insight",
            label: "Performance Insight",
            id: "Reports / Network Insight / Performance Insight",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Network Insight/Project ROS",
            label: "Project ROS",
            id: "Reports / Network Insight / Project ROS",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Network Insight/Opportunity Report",
            label: "Opportunity Report",
            id: "Reports / Network Insight / Opportunity Report",
            count: 0,
            children: [],
          },
          {
            to: "/reports/Network Insight/MRA Analysis",
            label: "MRA Analysis",
            id: "Reports / Network Insight / MRA Analysis",
            count: 0,
            children: [],
          },
        ],
      },
      {
        to: "/reports/Other BM Tools/All Reports",
        label: "Other BM Tools",
        id: "Reports / Other BM Tools",
        count: 0,
        children: [],
      },
      { to: "/reports/Batch Server", label: "Batch Server", id: "Reports / Batch Server", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  submissions: {
    url: "/submissions",
    src: <AddToPhotosIcon />,
    label: "Submission",
    to: "#",
    count: 0,
    className: "",
    children: [
      { to: "/submissions/upload-schedule", label: "Upload Schedule", id: "Submission / Upload Schedule", count: 0, children: [] },
      {
        to: "/submissions/templates",
        label: "Download Templates",
        id: "Submission / Download Templates",
        count: 0,
        children: [],
      },
      // { to: "/submissions/submit", label: "Submit Data", id: "Submission / Submit Data", count: 0, children: [] },
      { to: "/submissions/submission-status", label: "Submission Status", id: "Submission / Submission Status", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  insights: {
    url: "/insights",
    to: "#",
    src: <ShowChartIcon />,
    label: "Insights",
    count: 0,
    className: "",
    children: [
      {
        to: "/insights/data-submissions/data-preperation",
        label: "Data Preparation",
        id: "Insights / Data Preparation",
        count: 0,
        children: [],
      },
      {
        to: "/insights/data-submissions/data-interpretation",
        label: "Data Interpretation",
        id: "Insights / Interpretation",
        count: 0,
        children: [],
      },
      {
        to: "/insights/data-submissions/bm-process",
        label: "BM Process",
        id: "Insights / BM Process",
        count: 0,
        children: [],
      },
      {
        to: "/insights/data-submissions/industry-insights",
        label: "Industry Insights",
        id: "Insights / Industry Insights",
        count: 0,
        children: [],
      },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  actionPlan: {
    url: "/action-plan",
    to: "/action-plan/New",
    src: <LabelImportantIcon />,
    label: "Action Plan",
    count: 0,
    className: "joyride-start",
    children: [
      { to: "/action-plan/new", label: "New", id: "Action Plan / New", count: 0, children: [] },
      // { to: "/action-plan/Parts", label: "Parts", id: "Action Plan / Parts", count: 0, children: [] },
      // { to: "/action-plan/Used", label: "Used", id: "Action Plan / Used", count: 0, children: [] },
      // { to: "/action-plan/Service", label: "Service", id: "Action Plan / Service", count: 0, children: [] },
      // { to: "/action-plan/Body Shop", label: "Body Shop", id: "Action Plan / Body Shop", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
};

type ParentMenuLocationsType = {
  url: string;
  to: string;
  label: string;
  count: number;
  className: string;
  icon: string;
  children: ChildrenMenuLocationsType[];
  firstTab(availableLocations: string[], location: LocationType): void;
};

type ChildrenMenuLocationsType = {
  to: string;
  label: string;
  id: string;
  count: number;
  children: ChildrenMenuLocationsType[];
};

export const menuLocations: { [index: string]: ParentMenuLocationsType } = {
  notifications: {
    url: "/notifications",
    to: "/notifications",
    label: "notifications.label",
    count: 0,
    className: "",
    icon: "message",
    children: [{ to: "/notifications", label: "notifications.messages.subheader", id: "Notifications / Messages", count: 0, children: [] }],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
  about: {
    url: "/about",
    to: "/about/msxi",
    label: "about.label",
    count: 0,
    className: "",
    icon: "librarybooks",
    children: [
      { to: "/about/msxi", label: "about.msxi.bm.solution.subheader", id: "About / About MSXI BM Solution", count: 0, children: [] },
      { to: "/about/meet-the-team", label: "about.meet.the.team.subheader", id: "About / Meet the Team", count: 0, children: [] },
    ],
    firstTab: (availableLocations: string[], location: LocationType) => defaultRoute(availableLocations, location),
  },
};

const TruncatedText: React.SFC<{ className?: string; value: string; greaterThan?: number; truncateAter?: number }> = ({
  className,
  value,
  greaterThan = 7,
  truncateAter = 4,
}) => {
  if (value && value.length > greaterThan) {
    return (
      <Tooltip title={value}>
        <span className={className}>{`${value.substr(0, truncateAter)}...`}</span>
      </Tooltip>
    );
  }
  return <span className={className}>{value}</span>;
};

const defaultRoute = (availableLocations: string[], location: LocationType) => {
  const childLocations = location.label == "OEM Dashboard" ? [...location.children, ...fixedOemLocations, ...dynamicOemLocations] : location.children;
  const firstTab = childLocations
    .filter(tabLocation =>
      availableLocations.find(item => {
        if (tabLocation.id == "Reports / Dealer Insight" && item.includes("Reports / Dealer Insight")) {
          return true;
        }
        if (tabLocation.id == "Reports / Network Insight" && item.includes("Reports / Network Insight")) {
          return true;
        }
        return item == tabLocation.id;
      }),
    )
    .filter(t => !(t as any).placeholder);

  if (firstTab.length > 0) {
    return firstTab[0].to;
  }
  return "/";
};

type DropdownMenuType = {
  name: string;
  link: string;
  icon: string;
  mobile: boolean;
  visible: boolean;
};

const UnstyledLayout: React.FunctionComponent<DrawerProps> = props => {
  const { classes, children, width } = props;
  const [toggleNav, setToggleNav] = useState(isWidthDown("sm", width) ? false : true);
  const [pin, setPin] = useState(true);
  const [menuOpen, setMenuOpen] = useState(true);
  const { user } = useContext(UserContext);
  const { getPermissions, loading: permissionsLoading } = React.useContext(PermissionsContext);

  // Check if the user has the required permission to delete/create new notifications
  // Permissions for the admin items
  const permissionsData = getPermissions();

  const addPermissionsToChildren = (items, child) => {
    const hasPermission = !!permissionsData.find(perm => perm.moduleName == child.id && (perm.canRead || perm.canExec || perm.canWrite));
    items.children.push({
      ...child,
      hasPermission: child.id === "cube_reload" ? user.reloadAccess : hasPermission,
      ...(child.children || []).reduce(addPermissionsToChildren, {
        count: 0,
        children: [],
      }),
    });
    items.count = hasPermission ? items.count + 1 : items.count;
    return items;
  };

  const { tabs: accessTabs, loading: accessTabsLoading } = React.useContext(TabAccessContext);

  const departmentTabPermissions = accessTabs.filter((t: any) => t.name.includes("Dashboard / "));
  const hasSomeDepartmentPermissions = departmentTabPermissions.length > 0;
  const hasHomePermissions = accessTabs.filter((t: any) => t.name == "Dashboard / Home").length === 1;

  if (!hasHomePermissions && window.location.pathname == "/dashboard" && hasSomeDepartmentPermissions) {
    const permittedLocation = locations.dashboard.children.find(l => l.id == departmentTabPermissions[0].name);
    return <Redirect to={permittedLocation.to} />;
  }

  if (!accessTabsLoading.loaded || !permissionsLoading.loaded) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        {isWidthDown("sm", width) && (
          <div className={classnames(classes.menu)}>
            <div className={classes.filterburger}>
              <Burger className={classes.burger} onClick={() => setToggleNav(!toggleNav)} />
            </div>
            <div className={classes.logo}>
              <a href="/dashboard">
                <img src={msx} />
              </a>
            </div>
          </div>
        )}
        <MuiThemeProvider theme={theme}>
          {window.location.pathname !== "/filters" && (
            <div
              className={classnames(classes.nav, toggleNav ? classes.openNav : classes.closeNav)}
              onMouseEnter={() => setToggleNav(true)}
              onMouseLeave={() => !pin && setToggleNav(false)}
            >
              <DashboardDrawer
                open={true}
                onClick={() => setMenuOpen(!menuOpen)}
                toggle={toggleNav}
                setToggle={setToggleNav}
                pin={pin}
                setPin={setPin}
                className={classes.navMenu}
              />
              <button
                className={classes.pinMenu}
                onClick={() => {
                  if (pin) {
                    setToggleNav(false);
                  }
                  if (!pin) {
                    setToggleNav(true);
                  }
                  setPin(!pin);
                }}
              >
                {!false ? <span>{isWidthDown("sm", width) ? <FontAwesomeIcon icon={faWindowClose} /> : <FontAwesomeIcon icon={faThumbtack} />}</span> : null}
              </button>

              <ProfileMen toggle={toggleNav} />
            </div>
          )}
        </MuiThemeProvider>
        <div className={classnames(window.location.pathname === "/filters" ? classes.mainMargin : toggleNav ? classes.openMain : classes.closeMain)}>
          {children}
        </div>
      </main>
    </div>
  );
};

const ProfileMenu: React.FunctionComponent<DrawerProps> = ({ toggle, ...props }) => {
  const { classes } = props;
  const [showLogout, setShowLogout] = useState(false);
  const [logoutAnchor, setLogoutAnchor] = useState(null);
  const [languageAnchor, setLanguageAnchor] = useState(null);
  const [showLanguageOptions, setShowLanguageOptions] = useState(null);
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const [showSettingsOptions, setShowSettingsOptions] = useState(null);
  const { user } = useContext(UserContext);
  const { language, updateLanguageConfig, languageOptions } = useContext(LanguageContext);
  const { getPermissions, loading: permissionsLoading } = React.useContext(PermissionsContext);

  // Check if the user has the required permission to delete/create new notifications
  // Permissions for the admin items
  const permissionsData = getPermissions();

  const addPermissionsToChildren = (items, child) => {
    const hasPermission = !!permissionsData.find(perm => perm.moduleName == child.id && (perm.canRead || perm.canExec || perm.canWrite));
    items.children.push({
      ...child,
      hasPermission: child.id === "cube_reload" ? user.reloadAccess : hasPermission,
      ...(child.children || []).reduce(addPermissionsToChildren, {
        count: 0,
        children: [],
      }),
    });
    items.count = hasPermission ? items.count + 1 : items.count;
    return items;
  };

  const { tabs: accessTabs, loading: accessTabsLoading } = React.useContext(TabAccessContext);

  if (!accessTabsLoading.loaded || !permissionsLoading.loaded) {
    return <Loading />;
  }

  const availLocations: string[] = Object.values(accessTabs).map((tab: any) => tab.name);

  const menuLocationPermissions = (which: string): boolean => {
    return (
      menuLocations[which].children.filter((tabLocation: ChildrenMenuLocationsType) => availLocations.find((item: string) => item == tabLocation.id)).length > 0
    );
  };

  const dropdownMenuItems: DropdownMenuType[] = [
    {
      mobile: true,
      name: "menu.filters.button",
      link: "/filters",
      icon: "filteredList",
      visible: !location.pathname.includes("/filters") && user.usertype !== "D",
    },
    {
      mobile: false,
      name: "menu.profile.button",
      link: "/profile",
      icon: "user",
      visible: !location.pathname.includes("/profile"),
    },
    {
      mobile: false,
      name: "menu.admin.button",
      link: "/admin",
      icon: "settings",
      visible: availLocations.includes("Admin / Home") && !location.pathname.includes("/admin"),
    },
    {
      mobile: false,
      name: "notifications.label",
      link: "/notifications",
      icon: "message",
      visible: menuLocationPermissions("notifications") && !location.pathname.includes("/notifications"),
    },
    {
      mobile: false,
      name: "about.label",
      link: "/about/msxi",
      icon: "librarybooks",
      visible: menuLocationPermissions("about") && !location.pathname.includes("/about"),
    },
  ];

  const v1TermsRoute = {
    asean: "esosv2asean",
    australia: "esosv2aus",
    newzealand: "esosv2newzealand",
    china: "esosv2china",
    india: "esosv2india",
    southafrica: "esosv2sa",
  };
  return (
    <>
      <div className={classes.profileMenu}>
        <div
          className={classes.profile}
          onClick={e => {
            setShowLogout(true);
            setLogoutAnchor(e.currentTarget);
            // classnames(setToggleMenu ? "" : classes.transition);
          }}
        >
          <Popper anchorEl={languageAnchor} open={showLanguageOptions} transition className={classes.userPopup} placement="right-end">
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setShowLanguageOptions(false)}>
                    <MenuList>
                      {languageOptions.map(lang => (
                        <MMenuItem
                          key={lang.value}
                          className={classes.menuItems}
                          selected={lang.value === language}
                          onClick={() => {
                            updateLanguageConfig(lang.value);
                            setShowLanguageOptions(false);
                          }}
                        >
                          <Typography>{lang.label}</Typography>
                        </MMenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Popper anchorEl={settingsAnchor} open={showSettingsOptions} transition className={classes.userPopup} placement="right-end">
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setShowSettingsOptions(false)}>
                    <MenuList>
                      <MMenuItem
                        className={classes.menuItem}
                        onClick={() => {
                          setShowSettingsOptions(false);
                        }}
                      >
                        <Typography>
                          <FormattedMessage id={"settings.pdf.change.password"} />
                        </Typography>
                      </MMenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Popper anchorEl={logoutAnchor} open={showLogout} transition className={classes.userPopup} placement="right-end">
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setShowLogout(false)}>
                    <MenuList>
                      {dropdownMenuItems.find(dropdown => location.pathname.includes(dropdown.link)) && (
                        <MMenuItem
                          component="a"
                          href={"/dashboard"}
                          className={classes.menuItem}
                          onClick={() => {
                            setShowLogout(false);
                          }}
                        >
                          <ListItemIcon>
                            <Dashboard />
                          </ListItemIcon>
                          <MenuItemLabel classes={classes}>
                            <FormattedMessage id={"dashboard.dashboard.header"} />
                          </MenuItemLabel>
                        </MMenuItem>
                      )}
                      {dropdownMenuItems
                        .filter((item: DropdownMenuType) => item.visible)
                        .map((dropdownItem: DropdownMenuType, index: number) => (
                          <MenuComp
                            key={index}
                            link={dropdownItem.link}
                            icon={dropdownItem.icon}
                            name={dropdownItem.name}
                            mobile={dropdownItem.mobile}
                            visible={dropdownItem.visible}
                          />
                        ))}
                      <MMenuItem
                        className={classes.menuItem}
                        onClick={e => {
                          setLanguageAnchor(e.currentTarget);
                          setShowLanguageOptions(true);
                        }}
                      >
                        <ListItemIcon>
                          <TranslateIcon />
                        </ListItemIcon>
                        <MenuItemLabel classes={classes}>
                          <FormattedMessage id="language.label" />
                        </MenuItemLabel>
                        <span className={classes.languageArrow}>
                          <ChevronRightIcon />
                        </span>
                      </MMenuItem>
                      <MMenuItem
                        className={classes.menuItem}
                        onClick={e => {
                          setSettingsAnchor(e.currentTarget);
                          setShowSettingsOptions(true);
                        }}
                      >
                        <ListItemIcon>
                          <Settings />
                        </ListItemIcon>
                        <MenuItemLabel classes={classes}>
                          <FormattedMessage id="settings.label" />
                        </MenuItemLabel>
                        <span className={classes.languageArrow}>
                          <ChevronRightIcon />
                        </span>
                      </MMenuItem>
                      <MMenuItem
                        component="a"
                        target="_blank"
                        href={`https://${window.location.host
                          .split(".")[0]
                          .replace("qa", "")
                          .replace("staging", "")}2.sewellsgroupreporting.com/${
                          v1TermsRoute[window.location.host.split(".")[0].replace("qa", "")]
                        }/help/Terms%20and%20Conditions%20of%20Use%20and%20Privacy%20Policy.pdf`}
                        className={classes.menuItem}
                      >
                        <ListItemIcon>
                          <Terms />
                        </ListItemIcon>
                        <MenuItemLabel classes={classes}>
                          <FormattedMessage id={"dashboard.terms.and.con"} />
                        </MenuItemLabel>
                      </MMenuItem>
                      <MMenuItem
                        className={classes.menuItem}
                        onClick={() => {
                          setShowLogout(false);
                        }}
                        component="a"
                        href="/api/logout"
                      >
                        <ListItemIcon>
                          <ExitToAppTwoToneIcon />
                        </ListItemIcon>
                        <MenuItemLabel classes={classes}>
                          <FormattedMessage id="menu.logout.button" />
                        </MenuItemLabel>
                      </MMenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {toggle && (
            <Typography className={classnames(classes.profileName, toggle ? classes.openProfile : classes.closeProfile)}>
              <TruncatedText truncateAter={10} greaterThan={13} value={`${user.firstname} ${user.lastname}`} />
            </Typography>
          )}
          <Grid container justify="center" alignItems="center">
            <Avatar className={classes.profileImage} src={`/api/whoami/avatar.jpg?cachebuster=${new Date().toString()}`} />
          </Grid>
        </div>
      </div>
    </>
  );
};

export const MenuItemLabel = ({ classes, children }) => {
  return <Typography className={classes.menuItemLabel}>{children}</Typography>;
};

export const Layout = withStyles(styles, { withTheme: true })(withWidth()(UnstyledLayout));

export const ProfileMen = withStyles(styles, { withTheme: true })(withWidth()(ProfileMenu));
