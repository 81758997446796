import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import classnames from "classnames";
import React, { useState, useContext, useEffect } from "react";
import { Loading } from "../components/Loading";
import { Query } from "../lib/api/charts";
import { DashboardsConfig, DashboardConfig } from "../lib/api/user";
import { DealerContext } from "../contexts/DealerContext";
import { BrandContext } from "../contexts/BrandContext";
import { DashboardConfigContext } from "../contexts/DashboardConfigContext";
import { UserContext } from "../contexts/UserContext";
import { Select } from "../components/Select";
import withWidth, { isWidthUp, WithWidth, WithWidthOptions, isWidthDown } from "@material-ui/core/withWidth";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { Route } from "react-router-dom";
import { margin } from "../style/theme";
import { FilterBrandContext } from "../contexts/FilterBrandContext";
import { BenchmarkContext } from "../contexts/BenchmarkContext";
import { PeriodContext } from "../contexts/PeriodContext";
import { BenchmarkSelection } from "../Benchmark";
import { GroupDealerSelection, maxMonth } from "../GroupDealer";
import { GroupDashboardTabs } from "./GroupDashboardTabs";
import { GroupDateContext } from "../contexts/GroupFilterDateContext";
import { useIntl } from "react-intl";
import { CustomGroupDealerSelection } from "./dealer-selection/CustomGroupDealer";
import { isAfter, differenceInMonths, endOfMonth, subMonths } from "date-fns";
import { ExportButtons } from "../components/ExportButtons";
import { DropdownDescription } from "../lib/enum/dropdown-description";
import { RiskDashboardConfigContext } from "../contexts/RiskDashboardConfigContext";
import { OemComparativeDealer } from "../lib/api/flash-reports-config";
import { translate, translation } from "../translations/Translations";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { DashboardTabs } from "./DashboardTabs";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { Modal, Popover, Snackbar } from "@material-ui/core";
import { request } from "../lib/api/base";
import { RatesTable } from "../components/RatesTable";
import { InfoRounded } from "@material-ui/icons";
import { viewOptions as dashboardViewOptions } from "./../pages/custom/dealer/CustomInsight";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "16px 32px 16px 32px",
      marginBottom: "50px",
      ["@media (max-width:1030px)"]: {
        padding: "16px",
      },
    },
    body: {
      gridArea: "body",
    },
    filterburger: {
      "@media(max-width: 900px)": {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
      color: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#191919",
    },
    filterButton: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      fontSize: "14px",
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontWeight: "bold",
    },
    filterText: {
      margin: theme.spacing.unit * 2,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
    },
    burger: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      fontStyle: "bold",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    tabs: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      gridArea: "tabs",
      borderTop: "none",
      maxWidth: margin.width,
      margin: "auto",
      backgroundColor: "#191919",
    },
    wrapper: {
      backgroundColor: "#191919",
    },
    benchmarkSelection: {
      gridColumn: "6/8",
      "@media(max-width: 1230px)": {
        gridColumn: "4/6",
      },
    },
    filters: {
      margin: "auto",
      gap: "1rem",
      display: "grid",
      gridTemplateColumns: "repeat( 7, 1fr )",
      "@media(max-width: 900px)": {
        display: "block",
      },
      alignContent: "center",
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
      },
      gridArea: "filters",
      paddingLeft: "32px",
      paddingRight: "32px",
      // padding: "16px 32px 16px 32px",
      backgroundColor: "#191919",
      color: "#FFF",
      border: "none",
      maxWidth: margin.width,

      "@media (max-width: 1600px)": {
        paddingLeft: "32px",
        paddingRight: "32px",
      },
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    chipWrapper: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      },
      gridArea: "filters",
      border: "none",
      maxWidth: margin.width,
      flexBasis: "50%",
    },
    searchInput: {
      "@media(max-width: 900px)": {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: "12px",
      minWidth: "140px",
      height: "auto",
      color: "#333",
      fontWeight: "bold",
      zIndex: 100,
      // marginTop: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: "0px",
    },
    smallerInput: {
      minWidth: "160px",
    },
    mediumInput: {
      minWidth: "250px",
    },
    hideInput: {},
    menuWrapper: {
      background: "#191919",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      flexGrow: 1,
    },
    noFilter: {
      padding: theme.spacing.unit * 0.5,
    },
    chip: {
      margin: theme.spacing.unit,
    },
    placeHolderDiv: {
      width: "100%",
      margin: "auto",
      border: " none",
      maxWidth: "1300px",
      flexBasis: "50%",
    },
    exportBtn: {
      width: "100%",
      margin: "auto",
      border: "none",
      flexBasis: "50%",
    },
    exportBtnNoFlex: {
      width: "100%",
      margin: "auto",
      border: "none",
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      maxWidth: margin.width,
      margin: "auto",
    },
    topBarDashboardType: {
      gridTemplateColumns: "1fr 1fr",
      display: "grid",
      gridColumnGap: "1em",
      maxWidth: "1500px",
      margin: "auto",
    },
    BrandSearchInput: {
      "@media(max-width: 1030px)": {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: "12px",
      minWidth: "140px",
      height: "auto",
      color: "#333",
      fontWeight: "bold",
      zIndex: 100,
      // marginTop: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: "0px",
    },
    DealerSearchInput: {
      "@media(max-width: 1030px)": {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      borderRadius: theme.shape.borderRadius,
      flex: "0 0 auto",
      fontSize: "12px",
      minWidth: "140px",
      height: "auto",
      color: "#333",
      fontWeight: "bold",
      zIndex: 200,
      // marginTop: theme.spacing.unit,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      marginRight: "0px",
    },
  });

export type GroupDashboardOuterRenderProps = {
  query: Query;
  config: DashboardsConfig;
  updateConfig: (key: string, config: DashboardConfig) => void;
};

export type GroupDashboardOuterProps = {
  department: string;
  meta?: any;
  hidePeriod?: boolean;
  render: (props: GroupDashboardOuterRenderProps) => React.ReactNode;
  renderTabs?: (props: GroupDashboardOuterRenderProps) => React.ReactNode;
  filters?: (props: GroupDashboardOuterRenderProps) => React.ReactNode;
  title?: string;
  id?:
    | "metric-table"
    | "group-table"
    | "custom-table"
    | "dashboard-metrics"
    | "group-metrics"
    | "oem-metrics"
    | "custom-metrics"
    | "graph-table-dashboard"
    | "distribution-dashbord";
  displayExport?: boolean;
  showAvgSelector?: boolean;
  oemSpecific?: boolean;
  displayToggle?: boolean;
  max?: number;
  lockScorecardOptions?: boolean;
} & WithStyles<typeof styles> &
  WithWidthOptions &
  WithWidth &
  React.HTMLAttributes<HTMLDivElement>;

export const formatFullDate = (date?: Date) => {
  const d = new Date(Date.parse(date.toString()));
  d.setDate(d.getDate() + 1);
  return d.toJSON();
};

const monthDict = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const formatDisplayDate = (date: Date = new Date()) => {
  return `${monthDict[date.getMonth()]} ${date.getFullYear()}`;
};

const months = (
  date = new Date(2015, 1, 1),
  end = new Date(),
  period: string,
  populated: { value: string; label: string }[] = [],
): { value: string; label: string }[] => {
  const d = new Date(Date.parse(date.toString()));
  if (period == "Quarterly") {
    if ([2, 5, 8, 11].includes(d.getMonth())) {
      populated.push({ value: formatFullDate(d), label: formatDisplayDate(d) });
    }

    if (d.getFullYear() == end.getFullYear() && d.getMonth() == end.getMonth()) {
      return populated;
    }

    d.setDate(1);
    d.setMonth(d.getMonth() + 1);

    return months(d, end, period, populated);
  }

  populated.push({ value: formatFullDate(d), label: formatDisplayDate(d) });

  if (d.getFullYear() == end.getFullYear() && d.getMonth() == end.getMonth()) {
    return populated;
  }

  d.setDate(1);
  d.setMonth(d.getMonth() + 1);

  return months(d, end, period, populated);
};

export const addMonths = (date: Date, years: number) => {
  const d = new Date(Date.parse(JSON.stringify(date)));
  d.setMonth(d.getMonth() + years);
  return d;
};

type Option = { value: string; label: string };

const GroupDashboardOuterUnstlyed: React.FunctionComponent<GroupDashboardOuterProps> = ({
  classes,
  className,
  hidePeriod,
  renderTabs,
  department,
  render,
  meta,
  width,
  filters,
  title = "Group Dashboard",
  id = "group-metrics",
  displayExport = true,
  showAvgSelector = false,
  oemSpecific = true,
  displayToggle = false,
  max = 50,
  lockScorecardOptions = false,
}) => {
  const intl = useIntl();
  const { selected, selectedDealers, groupMinMonth, groupMaxMonth, loading, childrenDealers, view, setView } = useContext(DealerContext);
  const { date, updateDate, financialMonth, financialMonthOptions, setFinancialMonth } = useContext(GroupDateContext);
  const { brand, updateBrand } = useContext(FilterBrandContext);
  const brands = useContext(BrandContext);
  const configs = useContext(DashboardConfigContext);
  const targetCurrency = useContext(CurrencyContext);
  const [filtersOpen, setFiltersOpen] = useState(isWidthDown("sm", width) ? true : false);
  const benchmark = useContext(BenchmarkContext);
  const { allowDashboardAvg, loading: permissionsLoading } = useContext(PermissionsContext);
  const { periods, selected: period, setPeriod } = useContext(PeriodContext);
  const [showBenchmarkChip, setShowBenchmarkChip] = useState(true);
  const { user, loading: userLoading } = useContext(UserContext);
  const { comparativeDealers, loading: RiskComparativeLoading } = useContext(RiskDashboardConfigContext);
  const oemSpecificOption = oemSpecific ? selectedDealers.map(d => d.OEMSpecificDash).includes(true) : oemSpecific;
  const [ratesModalOpen, setRatesModalOpen] = useState(false);
  const currentOems = selectedDealers.map(cur => cur.oems).reduce((acc, array) => acc.concat(array), []);
  const { currencies } = targetCurrency;
  const formattedCurrencies = currencies.map(cur => ({
    label: intl.formatMessage({ id: translate(cur) }),
    value: cur,
  }));

  const [selectedAgg, setSelectedAgg] = React.useState(undefined);

  const noRegion = "no-region";
  const defaultFlashReport: Partial<OemComparativeDealer> = { name: "No Region", oemcode: "", dealercode: "" };
  const [selectedNetworkRegion, setSelectedNetworkRegion] = useState<Partial<OemComparativeDealer>>(defaultFlashReport);
  const [snackOpen, setSnackOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!loading.loaded && selectedDealers.length > 0) {
      const d = new Date(Date.parse(maxMonth(selectedDealers.map(dealer => dealer.maxMonth)).toString()));
      updateDate({
        value: formatFullDate(d),
        label: formatDisplayDate(d),
      });
    }
  }, [selectedDealers]);

  const availableBrands: Option[] = [
    {
      value: "",
      label: intl.formatMessage({ id: "all.brand.label" }),
    },
    ...(Object.values(
      Object.keys(brands.brands || {}).reduce((avail, code) => {
        const brand = brands.brands[code];
        if (selectedDealers.find(d => d.brands.includes(code))) {
          avail[brand] = { value: code, label: brand };
        }
        return avail;
      }, {}),
    ) as Option[]),
  ];

  const [metadata, setMeta] = useState(meta || {});

  const settingsConfig = configs.configs["settings"] || {
    oems: [],
    country: [],
  };

  const regionComparatives = comparativeDealers.filter(
    access => (selectedDealers.map(x => x.oems).flat() || []).includes(access.oemcode) && (settingsConfig.oems || []).includes(access.oemcode),
  );

  useEffect(() => {
    if (regionComparatives.length <= 0) {
      return setSelectedNetworkRegion(defaultFlashReport);
    }
    setSelectedNetworkRegion(regionComparatives[0]);
  }, [selectedDealers]);

  const query: Query = {
    date: date.value,
    department: department,
    dealers: selectedDealers,
    dealer: selected,
    brand: brands.brands[brand.value],
    forecast: { name: "", type: "", dealers: [], measures: [] },
    benchmarks: benchmark.selected,
    period: period,
    meta: { ...metadata, selectedAgg: selectedAgg },
    financialMonth: financialMonth,
    childDealers: childrenDealers,
    riskMetric: metadata.riskMetric,
    benchmarkDates: (benchmark.selected || [])
      .map(x => x.split(/-(?=[^-]+$)/)[0].trim())
      .reduce((obj, key) => ({ ...obj, [key as any]: benchmark.benchmarkDates[key as any] }), {}),
    targetCurrency: targetCurrency.targetCurrency,
    currencyPeriod: "average",
  };

  useEffect(() => {
    targetCurrency.getCurrencies([...currentOems]);
    targetCurrency.getRates(selectedDealers, query, benchmark.selected);
  }, [selectedDealers, targetCurrency.targetCurrency, targetCurrency.currencyPeriod]);

  if (
    !loading.loaded ||
    !benchmark.loading.loaded ||
    !configs.loading.loaded ||
    !userLoading.loaded ||
    !permissionsLoading.loaded ||
    RiskComparativeLoading.loading
  ) {
    return <Loading />;
  }

  if (lockScorecardOptions && (period !== "Calendar Year" || brand.value !== "")) {
    updateBrand({ value: "", label: intl.formatMessage({ id: "all.brand.label" }) });
    setPeriod("Calendar Year");
  }

  const currencyPeriodOptions = [
    {
      label: intl.formatMessage({ id: translate("Close") }),
      value: "close",
    },
    {
      label: intl.formatMessage({ id: translate("Average") }),
      value: "average",
    },
  ];

  const hasForecast = selectedDealers.filter(cur => cur.hasTargets).length > 0;

  const currencyOptions = [
    {
      label: intl.formatMessage({ id: translate("Base Currency") }),
      value: "",
    },
    ...formattedCurrencies,
  ];

  const userBrands = availableBrands.filter(brand => {
    if (user.usertype.toLocaleLowerCase() !== "o") {
      return true;
    }

    if (brand.value == "") {
      return true;
    }

    if (user.brands.length === 0) {
      return true;
    }

    return user.brands.includes(brand.value);
  });

  const noDataBms =
    benchmark.selected !== undefined &&
    benchmark.selected[0] != "" &&
    Object.keys(query.benchmarkDates)
      .filter(x => {
        const bmDate = endOfMonth(new Date(query.benchmarkDates[x].maxdate));
        return isAfter(endOfMonth(new Date(query.date)), bmDate) && differenceInMonths(endOfMonth(new Date(query.date)), bmDate) < 6;
      })
      .reduce((memo, val) => {
        const d = new Date(query.benchmarkDates[val].maxdate);
        memo[val] = {
          usedDate: `${monthDict[d.getMonth()]} ${d.getFullYear()}`,
        };
        return memo;
      }, {});

  if (!selected.dealer) {
    return (
      <div style={{ margin: "auto", maxWidth: margin.width, padding: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="headline">
          There are no dealers for your selected filters please go update them <a href="/filters">here</a> and try to view this page again.
        </Typography>
      </div>
    );
  }

  const defaultViewOptions = [
    { value: "mra", label: intl.formatMessage({ id: translate("MRA View") }) },
    { value: "standard", label: intl.formatMessage({ id: translate("Standard View") }) },
  ];
  const oemSpecificViewOptions = [{ value: "oemspecific", label: intl.formatMessage({ id: translate("OEM Specific View") }) }];
  const viewOptions = [...defaultViewOptions, ...(selected.OEMSpecificDash ? oemSpecificViewOptions : [])];

  const quarterlyMonth = () => {
    const selectedDate = subMonths(new Date(date.value), 1);

    let selectedMonth;
    if ([0, 1, 2].includes(selectedDate.getMonth())) {
      selectedMonth = 2;
    }
    if ([3, 4, 5].includes(selectedDate.getMonth())) {
      selectedMonth = 5;
    }
    if ([6, 7, 8].includes(selectedDate.getMonth())) {
      selectedMonth = 8;
    }
    if ([9, 10, 11].includes(selectedDate.getMonth())) {
      selectedMonth = 11;
    }

    return {
      value: formatFullDate(new Date(`${selectedDate.getFullYear()}-${selectedMonth + 1}-1`)).toString(),
      label: `${monthDict[selectedMonth]} ${selectedDate.getFullYear()}`,
    };
  };

  return (
    <>
      {!filtersOpen && isWidthDown("sm", width) && (
        <div className={classes.filterburger}>
          <div className={classes.filterButton}>
            <div className={classes.filterText}>Apply filters</div>
            <ExpandMore className={classes.burger} onClick={() => setFiltersOpen(true)} />
          </div>
        </div>
      )}
      {(filtersOpen || isWidthUp("md", width)) && (
        <div className={classnames(classes.wrapper, "joyride-filters")}>
          <Paper className={classes.filters}>
            {/* Dealer Selection Dropdown and Advanced Search Access Button */}
            <Route
              path="/:route/:dashboard?"
              render={({ match }) => {
                if (match.params.route == "oem" || match.params.dashboard == "dynamic") {
                  return (
                    <GroupDealerSelection
                      dashboard={match.params.dashboard}
                      dealerTypes="all"
                      className={classnames(classes.searchInput, "joyride-filters-dealer")}
                    />
                  );
                }
                if (match.params.route == "group-dashboard" || match.params.route == "oem" || match.params.route == "risk") {
                  return <GroupDealerSelection dashboard={match.params.dashboard} className={classnames(classes.searchInput, "joyride-filters-dealer")} />;
                }
                if (match.params.route == "custom" && match.params.dashboard == "distribution") {
                  return (
                    <CustomGroupDealerSelection
                      dashboard={match.params.dashboard}
                      className={classnames(classes.searchInput, "joyride-filters-dealer")}
                      max={150}
                    />
                  );
                }
                if (match.params.route == "custom" && match.params.dashboard == "group") {
                  return (
                    <CustomGroupDealerSelection
                      dashboard={match.params.dashboard}
                      className={classnames(classes.searchInput, "joyride-filters-dealer")}
                      max={150}
                    />
                  );
                }
                if (match.params.route == "custom" && match.params.dashboard == "group") {
                  return (
                    <CustomGroupDealerSelection
                      max={max}
                      dashboard={match.params.dashboard}
                      className={classnames(classes.searchInput, "joyride-filters-dealer")}
                    />
                  );
                }
              }}
            />
            {/* Benchmark Selection */}
            <BenchmarkSelection
              setBenchmark={benchmark.setBenchmark}
              benchmark={benchmark.selected}
              dealers={selectedDealers}
              hidePeriods={hidePeriod}
              className={classnames(classes.DealerSearchInput, classes.benchmarkSelection, classes.mediumInput)}
            />
            {selectedDealers.length > 0 && (
              <>
                <Select
                  className={classnames(classes.BrandSearchInput, classes.smallerInput, classes.hideInput, "joyride-filters-brand")}
                  value={brand.value == "" ? { value: brand.value, label: intl.formatMessage({ id: "all.brand.label" }) } : brand}
                  defaultInputValue={""}
                  placeholder="Brand"
                  isDisabled={lockScorecardOptions}
                  onChange={(value: Option) => updateBrand(value)}
                  options={[{ value: "disabled", label: DropdownDescription["BrandSelect"], isdisabled: true }, ...userBrands]}
                />
                {metadata.isRisk && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput)}
                    value={{
                      value: selectedNetworkRegion,
                      label: selectedNetworkRegion.name == noRegion ? "No regions available" : comparableCodeLabel(selectedNetworkRegion),
                    }}
                    onChange={value => setSelectedNetworkRegion(value.value)}
                    options={regionComparatives.map(access => ({ value: access, label: comparableCodeLabel(access) }))}
                  />
                )}
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput)}
                  value={{ value: period, label: intl.formatMessage({ id: translation[period] }) }}
                  isDisabled={lockScorecardOptions}
                  onChange={value => {
                    setPeriod(value.value);
                    if (value.value == "Quarterly") {
                      updateDate(quarterlyMonth());
                    }
                  }}
                  options={[
                    { value: "disabled", label: DropdownDescription["PeriodSelect"], isdisabled: true },
                    ...periods.map(opt => ({ value: opt, label: intl.formatMessage({ id: translation[opt] }) })),
                  ]}
                />
                {period == "Financial Year" && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-financialMonth")}
                    value={{ value: financialMonth, label: monthDict[financialMonth] }}
                    onChange={value => setFinancialMonth(value.value)}
                    options={[
                      { value: "disabled", label: DropdownDescription["FinancialMonthSelect"], isdisabled: true },
                      ...financialMonthOptions.map(opt => ({ value: opt, label: monthDict[opt] })),
                    ]}
                  />
                )}
                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-month")}
                  value={date}
                  onChange={value => updateDate(value as any)}
                  options={[
                    { value: "disabled", label: DropdownDescription["MonthSelect"], isdisabled: true },
                    ...months(new Date(groupMinMonth), new Date(groupMaxMonth), period).reverse(),
                  ]}
                />
                {displayToggle && (
                  <Select
                    className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-month")}
                    value={viewOptions.find(o => o.value === view)}
                    onChange={value => setView(value.value)}
                    options={[{ value: "disabled", label: DropdownDescription["ViewSelect"], isdisabled: true }, ...viewOptions]}
                  />
                )}

                <Select
                  className={classnames(classes.searchInput, classes.smallerInput, "joyride-filters-month")}
                  value={currencyOptions.length > 1 ? currencyOptions.filter(cur => targetCurrency.targetCurrency === cur.value) : currencyOptions[0]}
                  onChange={async value => {
                    const isDateAvailableQuery = await request("/currency/availability", {
                      method: "POST",
                      body: JSON.stringify({ query: query }),
                    });
                    const isDateAvailableResult = await isDateAvailableQuery.json();
                    if (!isDateAvailableResult.data.isAvailable) {
                      setSnackOpen(true);
                      return;
                    }
                    targetCurrency.updateTargetCurrency(value.value);
                  }}
                  options={currencyOptions}
                />

                {showAvgSelector && (
                  <>
                    <div>
                      <Select
                        className={classnames(classes.searchInput, classes.smallerInput)}
                        value={selectedAgg == "avg" ? { value: "avg", label: "Average by Dealer Count" } : { value: undefined, label: "Default" }}
                        onChange={value => setSelectedAgg(value.value)}
                        options={[{ value: undefined, label: "Default" }, { value: "avg", label: "Average by Dealer Count" }, ,]}
                      />
                    </div>
                  </>
                )}
                {filters && filters({ query, config: configs.configs, updateConfig: configs.updateConfig })}
              </>
            )}
            {isWidthDown("sm", width) && <ExpandLess className={classes.burger} onClick={() => setFiltersOpen(false)} />}
          </Paper>
          <GroupDashboardTabs dealer={selected} oemSpecificOption={false} className={classes.tabs} />
          {/* <GroupDashboardTabs dealer={selected} oemSpecificOption={selectedDealers.map(d => d.OEMSpecificDash).includes(true)} className={classes.tabs} /> */}
          {metadata.context && (metadata.context == "oem" || metadata.context == "fap") && <DashboardTabs dealer={selected} className={classes.tabs} />}
          {renderTabs && renderTabs({ query, config: configs.configs, updateConfig: configs.updateConfig })}
        </div>
      )}

      <div className={classnames(classes.root, className)}>
        <div className={classes.topBar}>
          <div>
            <Breadcrumbs />
            {displayExport ? (
              <ExportButtons
                className={noDataBms && Object.keys(noDataBms).length > 0 && showBenchmarkChip ? classes.exportBtn : classes.exportBtnNoFlex}
                title={title || department}
                id={id}
                filters={{
                  dealer: "",
                  brand: brand.label,
                  date: date.label,
                  forecast: "",
                  period: period,
                  financialMonth: monthDict[financialMonth],
                  benchmarks: benchmark.selected,
                  currency: targetCurrency.targetCurrency ? targetCurrency.targetCurrency : "Base currency",
                  view: dashboardViewOptions[query.meta.selectedAgg],
                }}
              />
            ) : (
              <div className={classes.placeHolderDiv} />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
            {noDataBms && Object.keys(noDataBms).length > 0 && showBenchmarkChip ? (
              <div className={classes.chipWrapper}>
                <Chip
                  icon={<InfoIcon />}
                  variant="outlined"
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  label={`${Object.keys(noDataBms)
                    .map(x => `${x}: ${noDataBms[x].usedDate}`)
                    .join(" | ")}`}
                  onDelete={() => setShowBenchmarkChip(!showBenchmarkChip)}
                  className={classes.chip}
                />
              </div>
            ) : (
              <div className={classes.placeHolderDiv} />
            )}
          </div>

          {!configs.oemSpecificView && displayExport && false && (
            <div>
              <Select
                className={classnames(classes.searchInput)}
                value={configs.dashboardTypeConfig}
                onChange={value => configs.setDashboardTypeConfig(value)}
                options={Object.values(configs.dashboardTypeConfigs)}
              />
            </div>
          )}
        </div>
        {query.date && render({ query, config: configs.configs, updateConfig: configs.updateConfig })}
      </div>
      <Snackbar
        style={{ marginTop: "1rem" }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        autoHideDuration={5000}
        message="Conversion rate not available for the selected time period"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Modal
        open={ratesModalOpen}
        onClose={() => setRatesModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <RatesTable onClose={() => setRatesModalOpen(false)} />
      </Modal>
    </>
  );
};

export const GroupDashboardOuter = withStyles(styles)(withWidth()(GroupDashboardOuterUnstlyed));

const comparableCodeLabel = (code: Partial<OemComparativeDealer>) => {
  if (code.oemcode.length == 0 && code.dealercode.length == 0) {
    return code.name;
  }
  return `${code.name} (${code.oemcode})`;
};
