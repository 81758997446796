import React, { createContext, useState, useMemo } from "react";

const defaultSelectedAdminPages = [];

export type SelectAdminPagesContextState = {
  selectedAdminPages: any[];
  setSelectedAdminPages(value: any[]): void;
  dealerCodeRedirect: string;
  setDealerCodeRedirect(value: string): void;
};

export const SelectAdminPagesContext = createContext<SelectAdminPagesContextState>({
  selectedAdminPages: defaultSelectedAdminPages,
  setSelectedAdminPages: () => {},
  dealerCodeRedirect: "",
  setDealerCodeRedirect: () => {},
});

export const SelectAdminPagesProvider = ({ children }) => {
  const [selectedAdminPages, setSelectedAdminPages] = useState<any[]>(defaultSelectedAdminPages);
  const [dealerCodeRedirect, setDealerCodeRedirect] = useState<string>("");

  const setConvertedSelectedAdminPages = (array: any[]) => {
    setSelectedAdminPages(array.filter((value, index, self) => index === self.findIndex(t => t.to === value.to)));
  };

  const value = useMemo(
    () => ({
      selectedAdminPages,
      setSelectedAdminPages: (value: any) => setConvertedSelectedAdminPages(value),
      dealerCodeRedirect,
      setDealerCodeRedirect,
    }),
    [selectedAdminPages],
  );

  return <SelectAdminPagesContext.Provider value={value}>{children}</SelectAdminPagesContext.Provider>;
};
