import { request } from "./base";

export type MenuItem = {
  reportType: string;
  reportName: string;
  dataSetId: number;
  reportSubPath: string;
  exportPDF: boolean;
  exportXLS: boolean;
  tooltip: string;
  url: string;
  folder?: string;
  docType?: "xls" | "pdf";
  description?: string;
  language?: string;
};

const types = {
  COMPOSITE: "Composite Reports",
  ANALYTICAL: "Analytical Reports",
  REPORTTOOLS: "Submission Templates",
};

const reportDocType = (item: any) => {
  if (item.exportPDF) {
    return "pdf";
  }
  if (item.exportXLS) {
    return "xls";
  }
  return null;
};

const languages = {
  Vietnamese: "vn",
  Japanese: "jp",
  Thai: "th",
  "Traditional Chinese": "tw",
  "Simplified Chinese": "cn",
  English: "en",
};

const invertedLanguages = {
  5: "vn",
  6: "jp",
  4: "th",
  3: "tw",
  2: "cn",
  1: "en",
};

export const getReportMenuItems = async (language: string): Promise<{ [index: string]: { [index: string]: MenuItem[] } }> => {
  const res = await request("reportmenu", {
    method: "GET",
  });
  const response = await request(`/allreports`, {
    method: "POST",
    body: JSON.stringify({ language: invertedLanguages[language] }),
  });
  const result = await res.json();
  const descriptions = await response.json();

  const data = result.data;
  return Object.keys(data).reduce((reports, key) => {
    const reportPath = data[key].reportSubPath.replace("/Dashboard/", "");
    const reportLanguage = languages[key.split("|")[1].trim()];

    const descriptionObj = descriptions.find(
      i => i.ReportName === data[key].reportName && i.ReportSubPath === data[key].reportSubPath && invertedLanguages[i.DashboardLanguageId] == reportLanguage,
    );

    const report = {
      ...data[key],
      reportSubPath: reportPath.includes("/") ? reportPath.split("/")[0] : reportPath,
      folder: reportPath.includes("/") ? reportPath.split("/")[1] : "",
      reportType: types[data[key].reportType] || "Dealer Reports",
      docType: reportDocType(data[key]),
      language: reportLanguage,
      description: descriptionObj ? descriptionObj.Description : undefined,
    };

    reports[report.reportSubPath] = reports[report.reportSubPath] || {};
    reports[report.reportSubPath][report.reportType] = reports[report.reportSubPath][report.reportType] || [];
    reports[report.reportSubPath][report.reportType].push(report);

    return reports;
  }, {});
};

export const getReports = async (language: string): Promise<[]> => {
  console.log("Results: ", language);
  const response = await request(`/reports`, {
    method: "POST",
    body: JSON.stringify({ language: language }),
  });
  if (response.status < 300) {
    const result = response.json();
    console.log("result: ", result);
    return result;
  }
  const err = { error: "Error", statusCode: response.status };
  throw err;
};

export const updateReportDescription = async (reportId: string, language: string, description: string) => {
  const response = await request(`/reports/update`, {
    method: "POST",
    body: JSON.stringify({ reportId: reportId, language: language, description: description }),
  });
  if (response.status < 300) {
    return true;
  }
  const err = { error: "Error", statusCode: response.status };
  throw err;
};
