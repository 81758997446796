import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Settings from "@material-ui/icons/Settings";
import classnames from "classnames";
import React, { useContext } from "react";
import { Loading } from "../../../components/Loading";
import { Metric, Query } from "../../../lib/api/charts";
import { CustomMetricContext } from "../../../contexts/Custom/MetricContext";
import { OemContext } from "../../../contexts/OemContext";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import { history } from "../../../lib/history";
import { Route } from "react-router-dom";
import { BenchmarkContext } from "../../../contexts/BenchmarkContext";
import { MetricInsightContext } from "../../../contexts/MetricInsightContext";
import { FormattedMessage } from "react-intl";
import { translation } from "../../../translations/Translations";
import { TruncatedMetricText } from "../../../charts/MetricCard";
import { prevVal } from "../../../charts/MetricCard";

export const styles = (theme: Theme) =>
  createStyles({
    cardRoot: {
      border: "1px #DADADA solid",
      height: "270px",
      borderRadius: "5px",
      // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !important",
      padding: `${theme.spacing.unit * 2}px`,
      color: "#191919",
    },
    cardContent: {
      height: "175px",
      display: "grid",
      gridTemplateAreas: `
      "value value value"
      "median median median"
      "median median median"
      "target target target"
      "target target target"
    `,
      gridGap: `${theme.spacing.unit}px`,
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "auto",
      backgroundColor: "#FFF",
      borderTop: 0,
      padding: theme.spacing.unit,
      // paddingTop: 0,
      color: "#191919",
    },
    header: {
      display: "grid",
      backgroundColor: "#FFF",
      borderBottom: 0,
      gridGap: `${theme.spacing.unit}px`,
      padding: theme.spacing.unit,
      paddingBottom: 0,
      color: "#191919",
    },
    bold: {
      fontWeight: "bold",
    },
    boldRight: {
      display: "flex",
      fill: "#636463",
      justifyContent: "flex-end",
      // fontWeight: 400,
      // paddingRight: "23px",
    },
    heading: {
      height: "55px",
    },
    headingChildren: {
      fontWeight: "bold",
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    mainTitleText: {
      fontSize: `${theme.typography.fontSize + 2}px`,
      whiteSpace: "nowrap",
    },
    mainTitle: {
      textDecoration: "underline",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "space-between",
    },
    median: {
      gridArea: "median",
      display: "grid",
      gridTemplateColumns: "6em auto",
      gridTemplateRows: "1fr 1fr",
    },
    title: {
      color: "#636463",
      fontWeight: 600,
      fontSize: `${theme.typography.fontSize + 3}px`,
      textDecoration: "none",
    },
    nowrap: {
      height: `${theme.typography.fontSize + 6}px`,
      whiteSpace: "nowrap",
    },
    bmValue: {
      color: "#636463",
      fontSize: `${theme.typography.fontSize + 3}px`,
      paddingLeft: "4px",
    },
    measurement: {
      gridArea: "measurement",
    },
    mainValue: {
      textAlign: "right",
      border: "solid 3px transparent",
      borderRight: "none",
      cursor: "pointer",
      alignItems: "center",
      marginBottom: 4,
      lineHeight: 0,
      fontSize: `${theme.typography.fontSize * 2.5 + 4}px`,
    },
    value: {
      gridArea: "value",
    },
    target: {
      gridArea: "target",
      gridTemplateRows: "1fr 1fr",
    },
    targetHeader: {
      fontSize: `${theme.typography.fontSize + 3}px`,
      color: "#636463",
    },
    targetValue: {
      fill: "#636463",
      fontSize: `${theme.typography.fontSize + 3}px`,
      fontWeight: "bold",
    },
    settings: {
      fill: "#636463",
      "&:hover": {
        cursor: "pointer",
      },
    },
    green: {
      color: "#4fdf4d",
    },
    yellow: {
      color: orange[500],
    },
    red: {
      color: red[500],
    },
    valueTargetIcon: {
      marginRight: "2px",
    },
    actions: {
      marginTop: "-22px",
      flexDirection: "row",
      alignItems: "center",
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        display: "flex",
      },
    },
    // actions: {
    //   marginTop: "-6em",
    //   flexDirection: "row",
    //   alignItems: "center",
    //   display: "none",
    //   [theme.breakpoints.up("sm")]: {
    //     display: "flex",
    //   },
    //   [theme.breakpoints.up("md")]: {
    //     display: "flex",
    //   },
    //   [theme.breakpoints.up("lg")]: {
    //     display: "flex",
    //   },
    //   [theme.breakpoints.up("xl")]: {
    //     display: "flex",
    //   },
    // },
    valueSpacing: {
      paddingLeft: theme.spacing.unit * 1,
    },
    lineSpacing: {
      paddingTop: theme.spacing.unit * 0.5,
    },
    hideCard: {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
    subheading: {
      fontSize: `${theme.typography.fontSize + 3}px`,
    },
  });

export type CustomMetricProps = {
  query: Query;
  title?: string;
  metric: any;
  allowInsights: boolean;
  renderIcon?(): React.ReactNode;
  custom?: any;
  forceTitle?: string;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

export type MetricHeaderProps = {
  title: string;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const MetricHeader: React.FunctionComponent<MetricHeaderProps> = ({ classes, title, children }) => {
  return (
    <div className={classnames(classes.title, classes.mainTitle, classes.bold, classes.header)}>
      {translation[title] ? (
        <FormattedMessage id={translation[title]}>
          {txt =>
            txt.toString().includes("-") ? (
              <span className={classes.heading}>
                <Typography className={classnames(classes.bold, classes.headingChildren)} variant="h6" color="inherit">
                  {txt.toString().split(" - ")[0]}
                </Typography>
                <TruncatedMetricText
                  className={classnames(classes.bold, classes.headingChildren, classes.subheading)}
                  value={txt.toString().split(" - ")[1]}
                  greaterThan={33}
                  truncateAter={30}
                />
              </span>
            ) : (
              <Typography className={classnames(classes.bold, classes.heading, classes.headingChildren)} variant="h6" color="inherit">
                {txt}
              </Typography>
            )
          }
        </FormattedMessage>
      ) : title.toString().includes("-") ? (
        <span className={classes.heading}>
          <Typography className={classnames(classes.bold, classes.headingChildren)} variant="h6" color="inherit">
            {title.toString().split(" - ")[0]}
          </Typography>
          <TruncatedMetricText
            className={classnames(classes.bold, classes.headingChildren, classes.subheading)}
            value={title.toString().split(" - ")[1]}
            greaterThan={33}
            truncateAter={30}
          />
        </span>
      ) : (
        <Typography className={classnames(classes.bold, classes.heading, classes.headingChildren)} variant="h6" color="inherit">
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
};

const criteriaMet = (metric: Metric, key: "previous" | "target" | "previousYear" | "previousPeriod") => {
  if (metric.classification == "income") {
    return metric.actual.value >= metric[key].value;
  }

  return metric.actual.value <= metric[key].value;
};

export type ValueTargetIconProps = {
  metric: Metric;
  criteriaMet: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const ValueTargetIcon: React.FunctionComponent<ValueTargetIconProps> = ({ classes, criteriaMet }) => {
  return (
    <FontAwesomeIcon
      className={classnames(classes.valueTargetIcon, criteriaMet ? classes.green : classes.red)}
      icon={criteriaMet ? faCheckCircle : faTimesCircle}
    />
  );
};

export type MainValueProps = {
  metric: Metric;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const metricColour = (metric: Metric, classes: any) => {
  if (metric.classification == "income") {
    return {
      [classes.yellow]: metric.actual.value >= metric.median.value && metric.actual.value < metric.benchmark.value,
      [classes.red]: metric.actual.value < metric.median.value,
    };
  }
  return {
    [classes.yellow]: metric.actual.value < metric.median.value && metric.actual.value > metric.benchmark.value,
    [classes.red]: metric.actual.value > metric.median.value,
  };
};

const MainValue: React.FunctionComponent<MainValueProps> = ({ classes, metric }) => {
  return (
    <div className={classnames(classes.value, classes.mainValue, classes.bold)}>
      <Typography
        className={classnames(classes.bold, classes.mainTitleText, classes.mainValue, metricColour(metric, classes))}
        variant="subheading"
        color="inherit"
      >
        {replaceNonNumbers(metric.actual.formatted)}
      </Typography>
    </div>
  );
};

const replaceNonNumbers = (value: any) => {
  if ((value || "").includes("NaN") || (value || "").includes("Infinity")) {
    return "";
  }
  return value;
};

const CustomMetricUnstyled: React.FunctionComponent<CustomMetricProps> = ({
  classes,
  title,
  metric: report,
  query,
  renderIcon,
  allowInsights,
  custom,
  forceTitle,
}) => {
  const { getCustomMetric } = useContext(CustomMetricContext);
  const { oems } = useContext(OemContext);
  const { metric, loading } = getCustomMetric(report, { ...query, department: custom.department }, custom);
  const { customSelected: benchmarks, loading: bmLoading } = useContext(BenchmarkContext);
  const { metricInsight, setMetricInsight } = React.useContext(MetricInsightContext);

  if (!loading.loaded || loading.loading || bmLoading.loading) {
    return <Loading />;
  }

  const metricTitle = forceTitle ? forceTitle : custom ? custom.label : title || metric.title;
  const metricName = custom ? custom.name : title || metric.title;

  const benchmarksType = benchmarks.map(x => {
    const [bench, part] = x.split(/-(?=[^-]+$)/);
    switch ((part || "").trim()) {
      case "MD":
        return "metric.median.label";
      case "BM":
        return "metric.benchmark.label";
      case "AV":
        return "metric.average.label";
      case "UL":
        return "metric.upper.limit.label";
      case "LL":
        return "metric.lower.limit.label";
      default:
        return "metric.benchmark.label";
    }
  });

  const bmTypeLookUp = {
    "MD:": "Median Value",
    "BM:": "Benchmark Value",
    "AVG:": "Average Value",
    "UL:": "Upper Limit Value",
    "LL:": "Lower Limit Value",
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <MetricHeader classes={classes} title={metricTitle}>
          <div className={classes.actions}>
            {renderIcon && renderIcon()}
            {allowInsights && metricTitle != "N/A" && !metricName.includes("OEM > Additional Stats >") && (
              <>
                <Route
                  path="/custom/:dashboard/:id?"
                  render={({ match }) => {
                    return (
                      <Settings
                        onClick={() => {
                          if (custom) {
                            setMetricInsight({ ...metricInsight, custom: custom.name, modelCustom: custom });
                          }
                          if (match.params.id !== undefined) {
                            history.push(`/custom/dealer/insight/${report}/${query.department}/${match.params.id}`);
                          } else {
                            history.push(`/custom/dealer/insight/${report}/${query.department}`);
                          }
                        }}
                        className={classes.settings}
                      />
                    );
                  }}
                />
              </>
            )}
            <Route
              path="/oem/fordscorecard/home"
              render={({ match }) => {
                return (
                  <Settings
                    onClick={() => {
                      if (custom) {
                        setMetricInsight({ ...metricInsight, custom: custom.name, modelCustom: custom });
                      }
                      history.push(`/oem/fordscorecard/home/insight/${report}/${query.department}`);
                    }}
                    className={classes.settings}
                  />
                );
              }}
            />
          </div>
        </MetricHeader>
        <CardContent className={classes.cardContent}>
          {benchmarks[0] && (
            <div className={classnames(classes.median)}>
              <Typography color="inherit" className={classnames(classes.title, classes.nowrap)} title={`${benchmarks[0]} - ${bmTypeLookUp[benchmarksType[0]]}`}>
                <FormattedMessage id={benchmarksType[0]} />
              </Typography>
              <Typography
                title={`${benchmarks[0]} - ${bmTypeLookUp[benchmarksType[0]]}`}
                className={classnames(classes.bold, classes.boldRight, classes.bmValue)}
              >
                {replaceNonNumbers(metric.benchmark.formatted)}
              </Typography>

              {benchmarksType.length > 1 && (
                <>
                  <Typography
                    color="inherit"
                    className={classnames(classes.title, classes.nowrap)}
                    title={`${benchmarks[1]} - ${bmTypeLookUp[benchmarksType[1]]}`}
                  >
                    <FormattedMessage id={benchmarksType[1]} />
                  </Typography>
                  <Typography
                    title={`${benchmarks[1]} - ${bmTypeLookUp[benchmarksType[1]]}`}
                    className={classnames(classes.bold, classes.boldRight, classes.bmValue)}
                  >
                    {replaceNonNumbers(metric.median.formatted)}
                  </Typography>
                </>
              )}
              {metric.dealerCount.value && metric.dealerCount.value > 0 ? (
                <>
                  <Typography color="inherit" className={classnames(classes.title, classes.nowrap, "dealer-count-label")}>
                    <FormattedMessage id={"metric.card.dealer.count.label"} />
                  </Typography>
                  <Typography className={classnames(classes.bold, classes.boldRight, classes.bmValue, "dealer-count-value")}>
                    {metric.dealerCount.value}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
          {metric && <MainValue classes={classes} metric={metric} />}
          <div className={classes.target}>
            {query.period == "TMRA" && metric.previousPeriod.formatted && metric.variancePrev.formatted && (
              <div>
                <span>
                  <ValueTargetIcon classes={classes} metric={metric} criteriaMet={criteriaMet(metric, "previousPeriod")} />
                  <span className={classnames(classes.targetHeader, classes.valueSpacing)}>{`${prevVal(query && `${query.period}-1`)}: `}</span>
                  <TruncatedMetricText
                    className={classes.targetValue}
                    truncateAter={8}
                    greaterThan={10}
                    value={metric.previousPeriod.formatted != "" ? replaceNonNumbers(metric.previousPeriod.formatted) : "N/A"}
                  />
                </span>
                <span className={classes.valueSpacing}>
                  <span className={classes.targetHeader}>Var </span>
                  <TruncatedMetricText
                    className={classes.targetValue}
                    truncateAter={8}
                    greaterThan={10}
                    value={
                      metric.previousPeriod.formatted != "" && metric.variancePrev.formatted != "" ? replaceNonNumbers(metric.variancePrev.formatted) : "N/A"
                    }
                  />
                </span>
              </div>
            )}
            {metric.previous.formatted === "" && metric.variance.formatted === "" ? (
              <div>
                <span style={{ visibility: "hidden" }}>-</span>
                {/* Placeholder to maintain element height */}
              </div>
            ) : (
              <div>
                <span>
                  <ValueTargetIcon classes={classes} metric={metric} criteriaMet={criteriaMet(metric, "previous")} />
                  <span className={classnames(classes.targetHeader, classes.valueSpacing)}>{`${prevVal(query && query.period)}: `}</span>
                  <TruncatedMetricText
                    className={classes.targetValue}
                    truncateAter={8}
                    greaterThan={10}
                    value={metric.previous.formatted != "" ? replaceNonNumbers(metric.previous.formatted) : "N/A"}
                  />
                </span>
                <span className={classes.valueSpacing}>
                  <span className={classes.targetHeader}>Var </span>
                  <TruncatedMetricText
                    className={classes.targetValue}
                    truncateAter={8}
                    greaterThan={10}
                    value={metric.previous.formatted != "" && metric.variance.formatted != "" ? replaceNonNumbers(metric.variance.formatted) : "N/A"}
                  />
                </span>
              </div>
            )}
            {query.period == "Monthly" && metric.previousYear.formatted && metric.variancePrev.formatted && (
              <div>
                <span>
                  <ValueTargetIcon classes={classes} metric={metric} criteriaMet={criteriaMet(metric, "previousYear")} />
                  <span className={classnames(classes.targetHeader, classes.valueSpacing)}>{`${prevVal(query && `${query.period}-1`)}: `}</span>
                  <TruncatedMetricText
                    className={classes.targetValue}
                    truncateAter={8}
                    greaterThan={10}
                    value={metric.previousYear.formatted != "" ? replaceNonNumbers(metric.previousYear.formatted) : "N/A"}
                  />
                </span>
                <span className={classes.valueSpacing}>
                  <span className={classes.targetHeader}>Var </span>
                  <TruncatedMetricText
                    className={classes.targetValue}
                    truncateAter={8}
                    greaterThan={10}
                    value={
                      metric.previousYear.formatted != "" && metric.variancePrev.formatted != "" ? replaceNonNumbers(metric.variancePrev.formatted) : "N/A"
                    }
                  />
                </span>
              </div>
            )}
            {query.dealer.hasTargets &&
              oems.filter(oem => query.dealer.oems.includes(oem.code)).find(oem => oem.hasTargets) &&
              (metric.target.formatted != "" || metric.targetVariance.formatted != "" ? (
                <div className={classes.lineSpacing}>
                  <span>
                    <ValueTargetIcon classes={classes} metric={metric} criteriaMet={criteriaMet(metric, "target")} />
                    <span className={classnames(classes.targetHeader, classes.valueSpacing)}>
                      <FormattedMessage id="metric.target.label" />
                      :&nbsp;
                    </span>
                    <TruncatedMetricText
                      className={classes.targetValue}
                      truncateAter={8}
                      greaterThan={10}
                      value={metric.target.formatted != "" ? replaceNonNumbers(metric.target.formatted) : "N/A"}
                    />
                  </span>
                  <span className={classes.valueSpacing}>
                    <span className={classes.targetHeader}>Var </span>
                    <TruncatedMetricText
                      className={classes.targetValue}
                      truncateAter={8}
                      greaterThan={10}
                      value={
                        metric.target.formatted != "" || metric.targetVariance.formatted != "" ? replaceNonNumbers(metric.targetVariance.formatted) : "N/A"
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className={classes.lineSpacing}>
                  <span style={{ visibility: "hidden" }}>-</span>
                  {/* Placeholder to maintain element height */}
                </div>
              ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export const CustomMetricCard = withStyles(styles)(CustomMetricUnstyled);
