import React, { createContext, useState, useMemo, useContext } from "react";
import { Loading } from "./base";
import { getReportMenuItems } from "../lib/api/reports";
import { LanguageContext } from "./LanguageContext";

const defaultReportFolder = {};

export type ReportFolder = { [index: string]: string[] };

export type ReportFolderContextState = {
  folders: ReportFolder;
  loading: Loading;
  folderReports: string[];
  hydrate();
};

export const ReportFolderContext = createContext<ReportFolderContextState>({
  folders: defaultReportFolder,
  loading: { loading: false, loaded: false, error: null },
  folderReports: [],
  hydrate: () => {},
});

export const ReportFolderProvider = ({ children }) => {
  const language = useContext(LanguageContext);
  const [folders, setFolders] = useState<ReportFolder>(defaultReportFolder);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const folderReports = ["Other BM Tools", "OEM Reports"];

  const value = useMemo(
    () => ({
      folders,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const data = await getReportMenuItems(language.language);
          const extract = folderReports
            .filter(f => Object.keys(data).includes(f))
            .reduce((reportFolders, r) => {
              const report = Object.values(data[r]).reduce((folders, reports) => {
                if (!folders[r]) {
                  folders[r] = [];
                }
                reports.map(report => {
                  if (report.folder.length > 0 && !folders[r].includes(report.folder)) {
                    folders[r].push(report.folder);
                  }
                });
                return folders;
              }, {});

              return { ...reportFolders, ...report };
            }, {});
          setFolders(extract);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      folderReports,
      setFolders,
    }),
    [folders, loading],
  );
  return <ReportFolderContext.Provider value={value}>{children}</ReportFolderContext.Provider>;
};
